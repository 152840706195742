:focus {
  outline: none;
}

::-moz-focus-inner {
  border: none;
}

html {
  position: relative;
  min-height: 100px;
}

html, body {
  height: 100%;
}

#topnav {
  margin: 0;
  border: none;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  height: 77px;
  @include clearfix;

  .navbar-brand {
    margin-top: -11px;
  }
}

#wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: auto;
}

#layout-static {
  display: table-row;
  height: 100%;
}

//Add top margin to any page with fixed header
//.navbar-fixed-top {
//    + #wrapper, + #layout-fixed {
//        padding-top: 77px !important;
//    }
//}

.navbar-fixed-top {
  width: 100%;
  position: fixed !important;
}

.static-sidebar-wrapper {
  display: table-cell;
  overflow: hidden;
  transition: 300ms ease-in-out
}

.static-sidebar-wrapper.opened {
  width: $sidebar-static-width;
}

.static-sidebar-wrapper.closed {
  width: $sidebar-static-width-sm;
}

.static-sidebar-wrapper {
  width: $sidebar-static-width;
}

@media (max-width: $screen-min) {
  .static-sidebar-wrapper {
    width: $sidebar-static-width-md;
    font-size: 12px;
  }

  .sidebar-container {
    width: $sidebar-static-width-md;
  }
}

.static-content-wrapper {
  display: table-cell;
  width: 100%;
}

.static-sidebar-wrapper, .static-content-wrapper {
  vertical-align: top;
}

footer {
  background: transparent;
  padding: 5px 16px;

  ul {
    margin: 0 !important;
  }
}

// White Background

.static-content-wrapper.content-white {
  background-color: #fff !important;
}

// Sticky Footer
.fixed-content, .horizontal-nav #wrapper {
  min-height: 100%;
  margin-bottom: -$footer-height;

  &:after {
    content: "";
    display: block;
  }

  + footer, &:after {
    height: $footer-height;
  }

  + footer {
    position: relative;
  }
}

//Footer at bottom
.static-content-wrapper {
  position: relative;
}

.static-content {
  margin-bottom: -$footer-height;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;

  h6 {
    font-weight: 400;
    font-size: $font-size-small;
    text-transform: uppercase;
  }
}

#back-to-top {
  color: $text-color;
}

@media (max-width: $screen-xs-max) {
  .page-content {
    padding: 0;
  }
}

/* Page Heading */
.page-heading {
  background: $page-title-color;
  padding: 24px;

  @include clearfix;
}

.page-heading h1 {
  float: left;
  margin: 0;
  line-height: 32px;
  font-weight: 300;
  font-size: 24px;
}

.page-heading .options {
  float: right;
}

/* Breadcrumb Mod */
.page-content > .breadcrumb {
  border: 0 !important;
  margin: 0 0 32px 0;
  padding: 12px 16px;
  display: inherit;
  background: $body-bg;
  border-radius: 0;
  font-size: $font-size-small;
  line-height: 16px;

  > li {
    font-size: $font-size-small;
    line-height: 16px;
  }
}

.page-content {
  margin-top: 15px;
}

.page-content > .page-heading + .breadcrumb {
  margin-top: 0;
}

// Hide some sections from being printed
@media print {
  .static-sidebar-wrapper, .demo-options, .page-heading, .page-content > .breadcrumb, footer {
    display: none;
  }
}

// Full Height Content
.full-height-content {
  .scroll-content {
    padding-right: 15px;
  }
}

.full-workspace-height {
  height: 100vh;
  padding-bottom: 0;

  .panel {
    margin-bottom: 0;
  }
}

.panel.full-workspace-height {
  margin-bottom: 0;
}
