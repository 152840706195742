@import "variables";
.form-group {



  ui-switch, sc-switch {
    display: inline-block;
    vertical-align: middle;
  }

  //label {
  //  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  //  font-size: 1em;
  //  font-weight: normal;
  //  line-height: 1.3;
  //  color: $eclipse;
  //
  //  &.optional {
  //    width: 100%;
  //
  //    > span {
  //      float: right;
  //      color: $grey-suit;
  //      font-size: 0.71em;
  //      font-family: Nunito, sans-serif;
  //      font-weight: bold;
  //    }
  //  }
  //
  //  .secondary-label {
  //    color: #cccccc;
  //    font-size: 13px;
  //  }
  //
  //  > small {
  //    cursor: pointer;
  //    color: $dusty-orange;
  //    &:hover {
  //      text-decoration: underline;
  //    }
  //  }
  //
  //}

  a {
    cursor: pointer;
  }

  .error-msg {
    color: $dusty-orange;
    border-radius: 5px;
    padding: 4px 4px 4px 20px;
    margin-top: 5px;
    font-size: 0.9em;
  }

  //textarea {
  //  padding: 8px;
  //}

  //input,
  //textarea {
  //  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  //  font-size: 1.0em;
  //  line-height: 1.3;
  //  border-radius: 5px;
  //  color: $eclipse;
  //  border: solid 1px #e9e9e9;
  //  box-shadow: none;
  //
  //  &::-webkit-input-placeholder {
  //    color: $sc-extra-light-gray;
  //  }
  //
  //  &:-ms-input-placeholder {
  //    color: $sc-extra-light-gray;
  //  }
  //
  //  &:-moz-placeholder {
  //    color: $sc-extra-light-gray;
  //  }
  //
  //  &:focus {
  //    outline: none;
  //    border: solid 1px #e9e9e9;
  //    box-shadow: none;
  //  }
  //}

  #add-files {
    width: 100%;
    height: 130px;
    border-radius: 4px;
    border: solid 1.5px $avocado-light;
    opacity: 0.6;
    text-align: center;

    span {
      width: 100%;
      opacity: 0.6;
      font-size: 1.3em;
      line-height: 1.3;
      text-align: center;
      color: $avocado-light;
    }

    img {
      width: 95%;
      height: 45px;
      margin: 10px;
    }

    input#file-select-input {
      width: 95%;
      height: 130px;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }
}

//.form-control.ng-invalid,
//.form-control.sc-invalid {
//  border: 1px solid $alert-danger-border;
//}
//
//.form-control.ng-pristine {
//  border: solid 1px #e9e9e9;
//  box-shadow: none;
//}

//input:focus {
//  outline: none;
//}

.group-subtitle {
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: left;
  font-size: 1.4em;
  color: $battleship-grey;
}

.group-title {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 1.4em;
  color: $battleship-grey;
}


.inner-addon {
  position: relative;
}

.inner-addon .fa {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  color: $sc-gray;
}

.inner-addon-sm {
  position: relative;
}

.inner-addon-sm .fa {
  position: absolute;
  padding: 7px;
  pointer-events: none;
  color: $sc-gray;
}

.left-addon .fa {
  left: 0px;
}

.right-addon .fa {
  right: 0px;
}

.left-addon input {
  padding-left: 30px;
}

.right-addon input {
  padding-right: 30px;
}


.attachments-container {
  width: 100%;
  border-radius: 4px;
  border: solid 1.5px $battleship-grey-extra-light;
  padding-top: 10px;
  padding-left: 10px;
}

.attachment-box {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
  margin: 0px 10px 10px 0px;
  border: solid 1.5px $sc-extra-light-gray;

  .attachment-img {
    width: 100%;
    height: 100%;
    padding: 5px;
  }
}

.attachment-action {
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
  line-height:13px;
  text-align: center;
  background-color: $dusty-orange;
  color: white;
  border-radius: 10px;
}


.form-error {
  color: $dusty-orange;
}

.avocado-checkbox {
  height: 20px;
  margin: 0 15px 0 0;
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid $avocado;
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label {
    background-color: $avocado;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -4px;
      top: 5px;
      height: 7px;
      width: 2px;
      background-color: white;
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }

    &::after {
      content: '';
      position: absolute;
      left: -3px;
      bottom: 3px;
      height: 2px;
      width: 10px;
      background-color: white;
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }
  }
}
