.pagination-container {
  position: relative;
}

.custom-pagination {
  position: relative;
  width: 250px;
  text-align: center;
  display: inline-block;

  .prev-date {
    position: absolute;
    top: 4px;
    left: 0px;
  }

  .next-date {
    position: absolute;
    top: 4px;
    right: 0px;
  }

  .date-field {

    .date-field-day {
      width: 100%;
      font-size: 13px;
      color: $sc-solid-blue;
    }

    .date-field-description {
      width: 100%;
      font-size: 15px;
      font-weight: 300;
      color: $avocado-light;
    }
  }

  .actions {
    float: right;
  }
}

.pagination-buttons-container {
  position: absolute;
  right: 16px;
  top: 8px;
}

.custom-pagination-actions {
  margin-top: 7px;
}
