@use 'types';

@tailwind base;
@tailwind utilities;

@layer base {
  input,
  textarea {
    @apply text-coal;
  }

  textarea {
    @apply resize-none p-8;

    &.control {
      @apply p-8;
    }

    &::-webkit-scrollbar-track {
      @apply bg-transparent;
    }

    &::-webkit-scrollbar {
      @apply w-4 bg-transparent;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-petro-n2 rounded-full;
    }
  }
}

@layer utilities {
  form .group {
    @apply flex flex-col;
    @extend %group;

    &-inline {
      @apply grid items-center;
      @extend %group;

      grid-template-columns: minmax(0, 1fr) auto;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'label input'
        'description input';

      label,
      .label-wrapper {
        @apply mb-0 pr-8;

        grid-area: label;
      }

      .label-wrapper > label {
        @apply mr-0;

        grid-area: unset;
      }

      > span {
        @apply mr-8 mt-0;

        grid-area: description;
      }

      input {
        grid-area: input;
      }
    }
  }

  .control {
    @apply bg-petro border-1 border-petro-n2 placeholder:text-coal-tertiary focus:outline-offset w-full rounded px-16 py-8 focus:outline;
    @extend .base-text;

    &[schema='new-primary'] {
      @apply focus:outline-new-primary focus:outline-offset-2;
    }

    &[disabled],
    &[readonly] {
      @apply bg-petro-n1 border-petro-n2 placeholder:text-coal-secondary cursor-not-allowed;
    }

    &.ng-touched.ng-invalid {
      @apply text-red border-red placeholder:text-red;
    }

    .text-input-wrapper > & ~ span {
      @apply text-red mt-4 uppercase;
      @extend .label-text;
    }
  }

  %group {
    .label-wrapper {
      @apply flex w-full items-center justify-between;

      > span {
        @apply text-coal-tertiary uppercase;
        @extend .label-text;
      }
    }

    label {
      @apply mb-4;
      @extend .base-text;
    }

    > span {
      @apply text-coal-secondary mt-4;
      @extend .small-text;
    }
  }
}
