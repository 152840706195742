.table {
  width: 100%;

  > thead {
    > tr {
      > th {
        font-size: 11px;
        color: $sc-gray;
        vertical-align: bottom;
        text-align: left;
        border-bottom: 1px solid $table-border-color;
        padding: 8px 0px 2px 0px;
        text-transform: uppercase;
      }
    }
  }

  > tbody {
    > tr {
      .selected {

      }

      > td {
        vertical-align: middle;
        padding: 20px 10px 20px 0px;
        font-size: 14px;
        color: #646464;
      }

      > td:first-child {
        border-radius: 5px 0 0 5px;
      }

      > td:last-child {
        border-radius: 0 5px 5px 0;
      }

      &:hover {
        > td.actions a{
          visibility: visible;
        }
        .info-data {
          .info-title {
            color: $avocado-light;
          }
          .info-label a {
            color: $avocado-light;
          }
        }
      }

      > td.actions {
        padding-right: 20px;

        > a {
          visibility: hidden;

          &:hover {
            text-decoration: underline;
          }
        }

        .row-action {
          cursor: pointer;
          font-size: 0.8em;
        }

        > a + a {
          margin-left: 2px;
        }

      }

      .action {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}
