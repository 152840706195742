#tsq-sidebar-menu {
  width: $tsq-sidebar-width-close;
  height: 100%;
  display: flex;
  flex-direction: column;

  border-right: 1px solid #EDEDED;

  overflow: hidden;
  transition: 500ms;

  .sidebar-toggler-container {
    height: 32px;
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;

    background-color: $avocado;

    cursor: pointer;

    &:hover {
      background-color: darken($avocado, 3%);
    }

    > img {
      width: 32px;
      transition: 500ms;
    }
  }

  .sidebar-content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    .content-scroll {
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
      }

      > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }
    }
  }
}

#tsq-sidebar-menu[open = "true"] {
  width: $tsq-sidebar-width-open;

  .sidebar-toggler-container > img {
    transform: rotateZ(180deg);
  }
}



/* Sidebar */

$active-bg: #537571;
$hover-bg: #6A918B;
$arrow-color: #b2ef4c;

.sidebar-container {
  //position: fixed !important;
  width: $sidebar-static-width;
  height: 100%;
  overflow-y: auto;
  background-color: $sidebar-bg-color;

  li.nav-separator {
    color: $siderbar-nav-separator-color;
    font-size: 10px;
    font-weight: bold;
    line-height: $line-height-small;
    text-transform: uppercase;
    display: block;
    padding: 32px 16px 5px 0px;
    border-bottom: 1px solid $siderbar-nav-separator-color;

    span {
      color: $battleship-grey-light;
      margin-left: 15px;
    }

    &:empty {
      font-size: 0;
      padding: 4px 0px;
    }
  }

}

.sidebar-container nav.sidebar > ul.acc-menu {
  height: 100%;
  white-space: normal;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
  padding: 0 0 77px 0; // need to scroll (offsetting top bar)

  li:first-child > a {
    border-top: 0;
  }

  .divider {
    height: 0px;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  ul {
    padding: 8px 0;
    padding-left: 0 !important;
    list-style: none;
  }

  ul, ul li a {
    border-top: none;
    box-shadow: none;
  }

  // Main Menu Styles - Level 1
  li, li a {
    white-space: nowrap;

    > .badge {
      @include clearfix;

      border-radius: 50px !important;
      background-color: $avocado;
      border: solid 2px #537571;
    }
  }

  li a {
    display: block;
    padding: 12px 16px 12px 0;
    position: relative;
    margin-right: 10px;
    margin-left: 15px;
  }

  li div:not(#divider):not(.child-item) {
    border-bottom: 1px solid $siderbar-nav-separator-color;
  }

  // Level 1 Icon styles
  menu-item > li > a > i, menu-item-group > li > a > i {
    margin-left: 0px;
    font-size: 17px;
    margin-right: 16px;
    min-width: 24px;
    text-align: center;
    display: inline-block;
  }

  @media (max-width: 1024px) {
    menu-item > li > a > i, menu-item-group > li > a > i {
      margin-right: 2px;
    }
  }

  li > a {
    color: #FFF;
  }

  // Submenu Styles - Level 2
  ul li a {
    padding: 6px 16px 6px 0px;
    font-weight: 400;
    color: #FFF;
    margin-left: 35px;

    > i {
      color: $avocado;
      margin-left: -7px;
      margin-right: 5px;
    }
  }

  menu-item > li.active > a, menu-item-group > li.active > a {
    font-weight: bold;
    color: #FFF;
  }

  menu-item > li.active, menu-item-group > li.active {
    background: linear-gradient(to right, $avocado 3%, $avocado 0%, $sidebar-bg-active-color 0%, $sidebar-bg-active-color 97%);
  }
  menu-item > li:hover:not(.active), menu-item-group > li:hover:not(.active):not(.open) {
    background: $hover-bg;
  }

  ul li.active:not(.open) > a {
    font-weight: 400;

    > span {
      width: 8px;
      border-radius: 50%;
      height: 8px;
      background-color: $avocado;
      position: absolute;
      left: -5px;
      top: 12px;
    }
  }

  li ul {
    display: none;
  }

  ul ul {
    padding: 0;
  }

  // Removes the extra stacked padding

  // All other levels
  li li li a {
    //padding-left: 72px;
  }

  //level 3
  ul ul ul li a {
    padding-left: 88px;
  }

  // level 4
  ul ul ul ul li a {
    padding-left: 104px;
  }

  // level 5

  li.disabled-link a {
    cursor: $cursor-disabled;
    font-style: italic;
  }
}

// Arrows
.sidebar-container nav.sidebar > ul.acc-menu li.hasChild {
  & > a {
    position: relative;
    @include clearfix;
  }

  & > a:before {
    content: "\f107";
    color: $arrow-color;
    margin-right: -15px;
  }

  &.open > a:before {
    content: "\f106";
    color: $arrow-color;
    margin-right: -15px;
  }

  & > a:before,
  &.open > a:before,
  &.open > a:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-size: 14px;
    padding-right: 0px;
    min-width: 16px;
    padding-top: 3px;
    // line-height: 24px;
    float: right;
  }

  &.open {
    background: linear-gradient(to right, $avocado 3%, $avocado 0%, $sidebar-bg-active-color 0%, $sidebar-bg-active-color 97%);

    > #divider {
      margin-left: 3%;
      border-bottom: 1px solid $sidebar-bg-color;
    }
  }
}

.sidebar-container nav.widget-body > ul.acc-menu li.hasChild li.hasChild a:before {
  top: 4px;
}

body.static-sidebar-wraper .sidebar nav.sidebar > ul.acc-menu > li.hasChild:hover a:before {
  content: "\f107";

  @include opacity(0.25);
}

// Turn arrow on sidebear collapsed hover
body.static-sidebar-wraper {
  .sidebar-container nav.sidebar > ul.acc-menu li.hasChild:hover > a:before {
    content: "\f107";
  }
}

// Necessary for Sidebar to work properly
.sidebar-collapsed .hasChild {
  ul.acc-menu {
    display: none !important;
  }

  &:hover ul.acc-menu {
    display: block !important;
  }
}

// Badges (notification ball)

.sidebar-container nav.sidebar > ul.acc-menu .badge,
.sidebar-container nav.sidebar > ul.acc-menu .label {
  position: absolute;
  right: 0;
  top: 15px;
}

// Labels

.sidebar-container nav.sidebar > ul.acc-menu .label {
  z-index: 1;
}

.sidebar-container nav.sidebar > ul.acc-menu > li.hasChild ul li .label {
  top: inherit;
  position: inherit;
  left: inherit;
  right: inherit;
}

.static-content-wrapper {
  background: $static-content-bg-color;
}



/* Collapse at small screens */

@media screen and (max-width: 767px) {

  .static-sidebar-wrapper, .sidebar-container {
    @include transition(width 0ms ease-out);
    width: $sidebar-static-width-sm;
  }

  .sidebar-container {
    overflow: auto;
    z-index: 10;

    nav.sidebar > ul.acc-menu {
      li {
        width: 102px;
        text-align: center;

        > a {
          padding: 5px 0px;
          margin: 0px;
          white-space: normal;

          > i {
            margin: 0px !important;
          }

          > span:first-of-type {
            width: 102px;
            display: inline-block;
            font-size: 12px;
          }
        }
      }

      li.hasChild a:before {
        display: none !important; // hide arrows
      }

      .badge {
        top: 2px !important;
        right: 15px !important;
      }

      li.hasChild.open {
        position: relative;

        .acc-menu {
          display: block;
          position: absolute;
          top: 0px;
          left: 103px;
          padding: 0px;
          z-index: 10;
          background-color: $sidebar-bg-color;

          li {
            width: auto;

            > a {
              border-bottom: 1px solid $battleship-grey-dark;
              border-left: none;
              padding: 10px 16px 10px 16px;
              margin: 0px;
              text-align: left;
              white-space: nowrap;

              > i {
                display: none;
              }
            }
          }
        }
      }

    }

    li.nav-separator {
      width: 52px !important;
      margin-left: 25px !important;
      margin-right: 0px !important;
      padding-top: 0px !important;

      > span {
        display: none;
      }
    }
  }

  .static-content-wrapper {
    overflow: hidden;
  }

}
