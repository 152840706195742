sc-calendar {

  .waiting-queue {
    color: $dusty-orange;
  }

  .waiting-approval {
    color: $light-burgundy
  }

  .current-user-event {
    color: $avocado;
  }

  .pending-confirmation {
    color: $dusty-orange;
  }

  .full-busy-day-caption {
    color: $sc-light-gray;
    font-size: 2.5em;
  }

  .busy-day-caption {
    color: $sc-light-gray;
    font-size: 2.5em;
  }

  .busy-day {
    border-radius: 50%;
    border: $sc-light-gray solid 2px;
  }

  .full-busy-day {
    border-radius: 50%;
    border: $sc-light-gray solid 2px;
    background-color: $sc-light-gray;
    color: $sc-gray
  }

  .caption {
    width:100%;
    padding-top: 10px;
    display: inline-block;
    div {
      white-space: nowrap;

      .caption-symbol {
        display: inline-block;
        vertical-align: middle;
        padding: 3px;
      }

      .caption-text {
        white-space: normal;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

mwl-calendar-month-view {
  .cal-month-view {
    mwl-calendar-month-view-header {
      .cal-cell-row {
        padding: 3px 0;
        border: none;

        &:hover {
          background-color: white;
        }

        .cal-cell {
          color: #999999;
          font-weight: normal;

          &:hover {
            background-color: white;
          }
        }
      }
    }

    .cal-days {
      border: none;

      .ng-star-inserted {
        .cal-cell-row {
          border: none;

          &:hover {
            background-color: white;
          }

          .cal-cell {
            color: #999999;
            font-weight: normal;
            border: none;

            &:hover {
              background-color: white;
            }
          }

          .cal-day-cell {
            min-height: 50px;

            .cal-cell-top {
              min-height: 50px;
              margin: 1px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              border: 1px solid #ebebeb;
              border-radius: 5px;
              transition: 300ms;

              .cal-day-number {
                margin: 0;
                color: $avocado;
                opacity: 1;
              }

              .event-bullet {
                width: 6px;
                height: 6px;
                position: absolute;
                bottom: 5px;
                background-color: $dusty-orange;
                border-radius: 50%;
              }
            }

            .cal-selected-day {
              background-color: $avocado;

              .cal-day-number {
                color: white;
              }

              .event-bullet {
                background-color: white;
              }
            }
          }

          .cal-today {
            background-color: white;

            .cal-cell-top > .cal-day-number {
              font-size: 1.2em;
            }
          }

          .cal-out-month {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
