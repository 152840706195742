.btn-pagination-report {
}

.btn-cfg {
    cursor: pointer;
    width: 30px;
}

.white-btn {
  color: $avocado;
  background-color: #ffffff;
  border: solid 1px #ededed;
  font-weight: normal;
  outline: none;
}
.white-btn:focus {
  color: $avocado !important;
  background-color: white;
  border: solid 1px #ededed;
  font-weight: normal;
  outline: none;
}
.white-btn:active:focus {
  color: $avocado !important;
  background-color: white;
  border: solid 1px #ededed;
  font-weight: normal;
  outline: none;
}
.white-btn:hover {
  background-color: darken(white, 3%);
  color: $avocado;
  font-weight: normal;
  outline: none;
}
.white-btn:disabled:hover {
  background: white;
}

.jelly-blue-btn {
  color: white;
  background-color: $jelly-bean-blue;
  border: none;
  font-weight: normal;
}

.jelly-blue-btn:focus {
  color: white;
  background-color: $jelly-bean-blue;
  border: none;
  font-weight: normal;
}

.jelly-blue-btn:active:focus {
  color: white;
  background-color: $jelly-bean-blue;
  border: none;
  font-weight: normal;
}

.jelly-blue-btn:hover {
  color: white !important;
  background-color: darken($jelly-bean-blue, 3%);
  border: none;
  font-weight: normal;
}

.jelly-blue-btn.disabled {
  color: white !important;
  background-color: $grey-suit;
  border: none;
  font-weight: normal;
}

.jelly-blue-btn[disabled] {
  color: white !important;
  background-color: $grey-suit;
  border: none;
  font-weight: normal;
}
