@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&display=swap);

@tailwind base;
@tailwind utilities;

@layer base {
  body {
    @extend .base-text;
    @apply font-base text-coal;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply mb-0 mt-0 #{!important};
  }

  h1 {
    @apply font-extra-bold text-xl leading-tight tracking-normal;
  }

  h2 {
    @apply font-extra-bold text-lg leading-normal tracking-normal;
  }

  h3 {
    @apply font-extra-bold text-base uppercase leading-normal tracking-wide;
  }

  h4 {
    @apply text-md font-bold leading-normal tracking-normal;
  }

  h5 {
    @apply text-md font-semi-bold leading-normal tracking-normal;
  }

  a {
    @apply text-primary hover:text-primary-p1 focus:outline-offset hover:no-underline focus:outline;
    @extend .base-text-bold;
  }
}

@layer utilities {
  .label-text {
    @apply font-extra-bold text-xs leading-normal tracking-wide;
  }

  .base-text {
    @apply font-regular text-base leading-normal tracking-normal;
  }

  .base-text-bold {
    @extend .base-text;
    @apply font-bold;
  }

  .small-text {
    @apply font-regular text-sm leading-relaxed tracking-normal;
  }

  .small-text-bold {
    @extend .small-text;
    @apply font-bold;
  }
}
