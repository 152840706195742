/*
  LABEL
*/

label {
  font-weight: normal;
}

label.required-label::after {
  content: '*';
  color: $battleship-grey-extra-light;
  padding-left: 2px;
}

.label {
  display: inline-block;
  padding: 5px;
  margin-top: 5px;
  margin-right: 5px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-primary {
  background-color: $dusty-orange;
}

.label-primary-o {
  background-color: transparent;
  border-color: $dusty-orange;
  color: $dusty-orange !important;
  padding: 4px;
  border-width: 1px;
  border-style: solid;
}

.label-blue-o {
  background-color: transparent;
  border-color: $avocado;
  color: $avocado;
  border-width: 1px;
  padding: 4px;
  border-style: solid;
}

.label-dark-blue-o {
  background-color: transparent;
  border-color: $battleship-grey;
  color: $battleship-grey;
  border-width: 1px;
  padding: 4px;
  border-style: solid;
}

.label-dark-orange-o {
  background-color: transparent;
  border-color: $dusty-orange;
  color: $dusty-orange;
  border-width: 1px;
  padding: 4px;
  border-style: solid;
}

.label-default {
  background-color: $sc-gray;
}

.label-blue {
  background-color: $battleship-grey;
}

.label-dark-orange {
  background-color: $dusty-orange;
}

.label-dark-blue {
  background-color: $battleship-grey-dark;
}

.label-light-blue {
  background-color: $battleship-grey-light;
}

.label-red-o {
  background-color: transparent;
  border-color: #d0021b;
  color: #d0021b;
  border-width: 1px;
  padding: 4px;
  border-style: solid;
}

div.instructions {
  color: #9b9b9b;
  margin-bottom: 20px;

  .header {
    font-weight: 400;
  }
  .description {
    margin-top: 20px;
  }
}

.form-caption {
  color: $battleship-grey-light;
  small {
    color: $sc-light-gray;
  }
}

.disabled-label {
  color: #e3e3e3 !important;
}
