.panel {
  margin-bottom: 15px;
  + .panel-transparent {
    box-shadow: 0;
  }

  .panel-heading {
    position: relative;

    .title {
      color: $sc-gray;
      font-size: 20px;
      width: 100%;

      .subtitle {
        color: $battleship-grey-extra-light;
        font-weight: 100;
        font-size: 18px;
      }
    }

    .title.title-info {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: bold;

      .fa {
        font-size: 18px;
      }
    }

    .toolbar {
      margin: 5px 0px 5px 0px;

      .search {
        padding-left: 0;

        input.form-control {
          border-radius: 20px !important;
        }
      }

      .toolbar-text {
        max-width: 760px;
      }

      .buttons {
        .btn {
          margin-left: 12px;
        }
      }

      p {
        color: $sc-light-gray;
        padding-bottom: 5px;
        text-align: justify;
      }
    }
  }

  .panel-footer {
    border-top: none;
    background-color: transparent;
  }
}

.panel-right-info {
  .panel-heading {
    padding: 5px 10px;
  }

  .panel-body p {
    text-align: left;
    font-size: 12px;
    color: #737070;
    word-break: break-word;
  }

  .panel-body img {
    margin-bottom: 15px;
  }

  .panel-body .table {
    margin-bottom: 0px;
    margin-top: -15px;

    > tbody > tr > td {
      padding: 10px 0px 10px 0px;
    }

    > thead > tr > th {
      padding-top: 0px;
    }
  }

  .panel-body .info-mini-card {
    padding: 0px;

    > .card {
      padding: 0px;
      border: none;
      text-align: left;

      > .info-picture {
        margin-top: 0px;
        float: none;
      }
    }

    .info-data {
      .info-title {
        font-size: 11px;
        color: $gray;
      }

      .info-small {
        small {
          font-size: 11px;
        }
      }
    }
  }

  .divider {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.panel-filter {
  background-color: $sc-deep-dark-blue;
  overflow-y: scroll;

  .panel-heading {
    .title {
      color: #fff;
      cursor: pointer;
    }
  }

  .divider {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .panel-body {
    padding-top: 0px;
  }

  .list-group {
    padding-left: 0;

    > .list-group-item {
      color: #fff;
      line-height: $line-height-small;
      display: block;
      background-color: transparent;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0 !important;
      padding-bottom: 8px;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 5px;

      > a {
        width: calc(100% - 29px);
        vertical-align: middle;
        display: inline-block;
        color: #fff;
        font-weight: 100;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      > span.label {
        display: inline-block;
        vertical-align: middle;
        margin: 0px;
        padding: 0px !important;
        font-size: 100%;
        font-weight: 100;

        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }

      &:hover {
        cursor: pointer;
        > a {
          text-decoration: none;
        }
      }
    }

    > .list-group-item.active {
      font-weight: bold;

      > a {
        font-weight: 600;
      }
    }
  }
}

.panel-filter-discussion {
  background-color: transparent;
  margin-left: 6px;
  overflow: visible;
  overflow-y: scroll;

  .triangle-arrow {
    position: relative;
  }

  .triangle-arrow:after {
    content: '';
    position: absolute;
    border: 6px solid;
    border-color: transparent $avocado transparent transparent;
    z-index: 1;
    right: 100%;
    margin-top: 5px;
  }

  .panel-heading {
    background-color: $avocado;
    .title {
      font-size: 12px;
    }
  }

  .panel-body {
    background-color: white;
    padding: 0;
  }

  .list-group {
    margin-bottom: 0;

    > .list-group-item {
      border-bottom: 1px solid #f4f4f4;

      > a {
        font-size: 13px;
        padding-left: 15px;
        padding-right: 15px;
        color: $avocado;
      }
    }

    > .list-group-item:last-child {
      border-bottom: transparent;
      margin-bottom: 5px;
    }

    > .list-group-item.active {
      font-weight: bold;

      > a {
        font-weight: 600;
        color: $battleship-grey;
      }
    }
  }

  .lock-icon {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 9px;
    margin-top: 3px;
    background: url('/assets/img/icons/lock-rounded-border.svg') no-repeat center;
    background-size: 20px;
  }
}

.tsq-card {
  border-radius: 5px;
  background-color: white;
  box-shadow: 5px 5px 20px rgba(28, 46, 82, 0.15);

  .divider {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
    background-color: $white-smoke;
  }

  .divider-darker {
    background-color: $whisper;
  }

  .vertical-divider {
    width: 1px;
    height: 100%;
    background-color: $white-smoke;
  }
}

.tsq-filter-list {
  margin-bottom: 16px;

  ul {
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;

      i {
        margin-right: 8px;
      }
    }

    li.list-title {
      padding: 16px;
      color: white;
      background: $avocado;
      border-radius: 5px 5px 0 0;
    }

    li:not(.list-title) {
      padding: 8px 8px 8px 16px;
      color: $avocado;
      cursor: pointer;

      i {
        display: none;
      }

      a {
        flex-grow: 1;
        word-break: break-all;
      }

      img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
        flex-shrink: 0;
        background-size: 20px;
        cursor: pointer;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid $white-smoke;
      }

      &.active {
        i {
          display: block;
        }

        a,
        i {
          color: $battleship-grey;
        }
      }

      &.loading-li {
        justify-content: center;
        transform: translateX(-5px);
      }
    }

    .li-error {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > span {
        margin-bottom: 16px;
        color: $battleship-grey;
        text-align: center;
      }

      > button {
        padding: 7px 15px;
        font-size: 0.9em;
        margin-bottom: 8px;
      }
    }
  }
}
