.inline-block {
  display: inline-block;
}

.full-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical-centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.block {
  display: block;
}

.full-width {
  width: 100%;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auto-cursor {
  cursor: auto;
}

.auto-cursor-important {
  cursor: auto !important;
}

.pointer-cursor {
  cursor: pointer;
}

.pointer-cursor-important {
  cursor: pointer !important;
}

.parent-link {
  cursor: pointer;
}

.selected {
  background-color: $sc-deep-dark-blue;
}

.first-video {
  .modal-dialog {
    width: 655px !important;
  }
}

.resident-list-card-height {
  height: 110px;
}

.info-mini-card {
  width: 100%;
  margin: 5px;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  //text-overflow: ellipsis;

  > .card {
    padding: 20px;
    border: 2px solid #eee;
    border-radius: 6px;
  }

  .info-picture.info-second-picture img {
    height: 25px;
    width: 25px;
  }

  .info-picture.info-second-picture.info-picture-auto {
    margin: 0px 0px 0px 10px;
  }

  .info-label {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: $battleship-grey;
    width: calc(100% - 70px);
    display: inline-block;
    vertical-align: middle;
    a {
      white-space: pre-wrap;
    }
  }

  .info-data.info-middle.info-auto {
    width: auto;
    min-width: 150px;
  }

  .info-label.info-auto {
    width: auto;
    min-width: 150px;
  }

  .info-data.info-middle {
    width: -moz-calc(100% - 100px);
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
  }

  .info-data {
    width: -moz-calc(100% - 49px);
    width: -webkit-calc(100% - 49px);
    width: calc(100% - 49px);

    display: inline-block;
    vertical-align: middle;
    white-space: normal;

    .info-title {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      color: $battleship-grey;
      width: 100%;
      min-width: 94px;

      .selected & {
        color: $battleship-grey;
      }

      a {
        .selected & {
          color: $battleship-grey;
        }
      }

      .active & {
        color: $battleship-grey;
      }

      &.unread {
        color: $avocado;
      }

      > button {
        padding: 0px 15px 0px 15px;
        margin-left: 15px;
      }
    }

    .selected-info-title {
      @extend .info-title;
      color: $battleship-grey;
    }

    .info-small {
      small {
        text-align: left;
        font-size: 13px;
      }

      .selected & {
        color: white;
      }
      width: 100%;
    }
  }

  .info-date {
    .info-title {
      font-size: 18px;
      text-align: center;
      color: $dusty-orange;
      width: 100%;
    }

    .info-small {
      margin-top: -5px;

      small {
        text-align: center;
        font-size: 15px;
        color: $dusty-orange;
      }

      width: 100%;
    }
  }
}

.info-picture,
.info-label,
.info-data {
  display: inline-block;
}

.info-picture {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
}

.simplified-img {
  margin-right: 10px;
  display: inline-block;
  object-fit: cover;
  img {
    width: 40px;
    height: 40px;
  }
}

.feed-card {
  .simplified-img {
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.info-mini-card.extra-small-card {
  padding-left: 70px;

  .simplified-img {
    margin-right: 5px;
    img {
      width: 25px;
      height: 25px;
    }
  }

  .info-label {
    font-size: 11px;
  }

  .info-data {
    .info-small {
      font-size: 0.7em;
      height: 18px;
    }
  }

  .info-picture.info-second-picture img {
    height: 20px;
    width: 20px;
  }
}

/* end of info cards*/

.info-protocol {
  width: 100%;
  padding: 5px;
  display: table;
  position: relative;
  margin-left: 4px;

  .info-title {
    font-size: 13px;
    color: $sc-gray;
    width: 100%;
    margin-bottom: 5px;
  }
}

.text-status {
  font-size: 13px;
  color: $sc-gray;
}

.divider {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 6%;
  padding-left: 6%;
  background-color: #f4f4f4;
  height: 1px;
}

.affix {
  overflow-y: scroll;
}

.affix-left {
  width: -moz-calc(16.66% - 48px);
  width: -webkit-calc(16.66% - 48px);
  width: calc(16.66% - 48px);
}

.affix-left-sm {
  width: calc(25% - 48px);
  width: -webkit-calc(25% - 48px);
  width: calc(25% - 48px);
}

.affix-right {
  width: calc(33% - 75px);
  width: -webkit-calc(33% - 75px);
  width: calc(33% - 75px);
  height: calc(100% - 77px);
  max-height: 100%;
  min-height: 100px;
  overflow-y: scroll;
}

.affix-right-small {
  width: calc(33% - 165px);
  width: -webkit-calc(33% - 165px);
  width: calc(33% - 165px);
  max-height: 100%;
  min-height: 100px;
  overflow-y: scroll;
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .affix-left {
    width: calc(25% - 65px);
    width: -webkit-calc(25% - 65px);
    width: calc(25% - 65px);
  }

  .affix-left-sm {
    width: calc(25% - 65px);
    width: -webkit-calc(25% - 65px);
    width: calc(25% - 65px);
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .affix-left {
    width: calc(25% - 40px);
    width: -webkit-calc(25% - 40px);
    width: calc(25% - 40px);
  }

  .affix-left-sm {
    width: calc(25% - 40px);
    width: -webkit-calc(25% - 40px);
    width: calc(25% - 40px);
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .affix-right {
    width: calc(33% - 45px);
    width: -webkit-calc(33% - 45px);
    width: calc(33% - 45px);
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .affix-right-small {
    width: calc(33% - 135px);
    width: -webkit-calc(33% - 45px);
    width: calc(33% - 45px);
  }
}

.full-workspace-height {
  //height: 100vh;
  //overflow-y: scroll;
  //padding-bottom: $navbar-height;
  height: 85%;
}

.full-height {
  height: 100%;
  min-height: 640px;
}

.img-center {
  margin: 0 auto;
}

.img-profile {
  width: 40px;
}

.text-profile {
  font-size: 0.8em;
}

.text-info {
  color: $avocado !important;
}

.text-info-dark {
  color: $battleship-grey !important;
}

.text-info-deep-dark {
  color: $sc-deep-dark-blue !important;
}

.text-warning {
  color: $dusty-orange !important;
}

.text-warning-dark {
  color: $dusty-orange !important;
}

.text-muted {
  color: $sc-gray !important;
}

.text-white {
  color: #ffffff !important;
}

.text-bold {
  font-weight: bold;
}

.text-divider {
  font-weight: bold;
  font-size: 11px;
  color: #9b9b9b;
  vertical-align: bottom;
  text-align: left;
  text-transform: uppercase;
}

.no-padding-h {
  padding-left: 0;
  padding-right: 0;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.divider-right {
  border-right: 2px solid #eee;
}

/* CALENDAR */
#calendar {
  > h2 {
    font-size: 16px;
    text-transform: lowercase;
    color: $avocado;
  }
}

.fc-basic-view .fc-body .fc-row {
  min-height: 2em;
}

.fc-toolbar h2 {
  font-size: 16px;
  text-transform: lowercase;
  color: $avocado;
}

.fc th {
  font-size: 8px;
  padding: 5px !important;
  color: $dusty-orange;
  text-transform: lowercase;
}

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: center !important;
  color: $battleship-grey;
  font-size: 0.8em;
}

.fc-other-month {
  color: $sc-gray !important;
}

.fc-past {
  color: $sc-gray !important;
  // opacity: 0.3;
}

.fc button {
  height: 1.5em;
  color: $sc-light-blue;
  border-radius: 65% !important;
  background: none;
  padding: 0.1em;
  border-color: #81abd8 !important;
}

.fc button .fc-icon {
  top: 0;
  margin: 0;
}

.fc-icon-left-single-arrow:after {
  right: -15%;
  font-weight: initial;
}

.fc-icon-right-single-arrow:after {
  right: 15%;
  font-weight: initial;
}

.fc-event {
  width: 5px;
  height: 5px;
  padding: 0px;

  .blue-event {
    background-color: $sc-solid-blue;
  }

  .orange-event {
    background-color: $dusty-orange;
  }

  > .fc-rounded {
    > .fc-content {
      display: none !important;
    }
  }
}

.fc-rounded > .fc-content {
  display: none !important;
}

/* MAIN CALENDAR */
#main-calendar.fc-ltr .fc-basic-view .fc-day-number {
  font-size: 1.5em;
  padding-top: 15px;
}

#main-calendar.fc th {
  font-size: 13px;
  font-weight: initial;
}

.main-calendar-label {
  margin-top: 20px;
}

/* SCHEDULES */
#schedules {
  .fc-head,
  .fc-divider.fc-widget-header {
    display: none !important;
  }

  .fc-day-grid {
    display: none !important;
  }

  table tbody tr {
    height: 50px;
  }
}

/*
    BUBBLE
*/
.bubble {
  position: relative;
  display: inline-block;
  width: 80%;
  padding: 10px;
  background: #ffffff;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  border: rgba(155, 155, 155, 0.5) solid 1px;
}

.bubble-info {
  background-color: #f4f4f4;

  &:after {
    border-color: transparent #f4f4f4 !important;
  }
}

.bubble-left {
  margin-left: 30px;
}

.bubble-left:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0px 25px 20px 0px;
  border-color: transparent #cdcdcd;
  display: block;
  width: 0px;
  z-index: 0;
  left: -26px;
  top: 14px;
}

.bubble-left:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0px 28px 21px 1px;
  border-color: transparent #ffffff;
  display: block;
  width: 0;
  z-index: 1;
  left: -25px;
  top: 15px;
}

.bubble-left.bubble-info:before {
  border-color: transparent $sc-solid-blue;
}

.bubble-right {
  margin-right: 30px;
  text-align: left;
}

.bubble-right:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0px 0 20px 25px;
  border-color: transparent #ebebeb;
  display: block;
  width: 0;
  z-index: 1;
  right: -25px;
  top: 14px;
}

.bubble-right {
  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0px 0 20px 25px;
    border-color: transparent rgba(155, 155, 155, 0.5);
    display: block;
    width: 0;
    z-index: 0;
    right: -26px;
    top: 14px;
  }
}

.conversation {
  width: 100%;
  padding: 10px 25px;
  display: inline-block;
  position: relative;

  .info-picture {
    vertical-align: top;
  }
}

.conversation-right {
  .simplified-img {
    margin-left: 10px;
    margin-right: 0px;
  }

  .conversation-bubble {
    text-align: right;
  }
}

.conversation.conversation-warning {
  background-color: $dusty-orange;
  border-radius: 16px;
}

.conversation.conversation-default {
  background-color: $sc-gray;
  border-radius: 16px;
}

.conversation-bubble {
  display: inline-block;
  width: calc(100% - 76px);
}

.information-balloon {
  border-radius: 4px;
  padding: 10px 20px 12px 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  .balloon-title {
    color: $sc-solid-blue;
    font-size: 14px;
    font-weight: bold;
  }

  .balloon-subtitle {
    font-size: 12px;
    color: $sc-dark-gray;
    font-style: italic;
  }

  .balloon-description {
    margin-top: 6px;
  }
}

.light-blue-border {
  border: solid 1.5px $avocado-light;
}

/* USER MENU */

profile-dropdown {
  float: left;
  width: 250px;
  padding-left: 15px;
}

.user-menu {
  margin-right: 10px;
  img {
    object-fit: cover;
  }

  > li {
    margin-top: 15px;

    &.separator {
      padding-bottom: 50px;
      border-right: 1px solid $sc-gray-transparent;
      margin-top: 15px;
    }

    > a {
      padding-top: 0px;
      padding-bottom: 0px;

      > i {
        font-size: 1.2em;
        color: $dusty-orange;
      }

      > p {
        margin: 5px 10px 0px 5px;
        float: right;
      }

      &.dropdown-toggle > img {
        width: 50px;
        height: 50px;
        margin-left: 3px;
        object-fit: cover;
      }

      &.user-menu-option {
        padding: 10px 10px 0px 0px;
      }
    }
  }
}

#register {
  animation-duration: 600ms;
  .register-modal {
    float: right;
  }
}

mark {
  background-color: yellow;
  color: black;

  &.highlight {
    background: #f1ad004d;
  }
}

.action {
  cursor: pointer;
}

.no-padding-left {
  padding-left: 0px;
}

.no-padding-right {
  padding-right: 0px;
}

.single-item-tr {
  padding-left: 10px;
}

.public-col-container {
  .btn-default-dark {
    margin-bottom: 20px;
    font-weight: 100;
  }

  .btn-default {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .lines-sided-text {
    line-height: 0.5;
    text-align: center;
    margin-bottom: 20px;
  }

  .lines-sided-text span {
    display: inline-block;
    position: relative;
    color: $battleship-grey;
  }

  .lines-sided-text span:before,
  .lines-sided-text span:after {
    content: '';
    position: absolute;
    height: 5px;
    border-bottom: 1px solid #ededed;
    top: 0;
    width: 176px;
  }

  .lines-sided-text span:before {
    right: 98%;
    margin-right: 15px;
  }

  .lines-sided-text span:after {
    left: 98%;
    margin-left: 15px;
  }
}

.public-col-message {
  color: $sc-solid-blue;
  width: 260px;
  text-align: center;
  margin: 0 auto;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
  }
}

.no-pointer-event {
  pointer-events: none;
}

// This is here because it needs to be available outside the view scope
.tooltip {
  .tooltip-inner {
    background-color: $battleship-grey-light;
    color: #fff;
    border-radius: 10px;
  }
  .tooltip-arrow {
    border-top-color: $battleship-grey-light !important;
  }
}

/* Selection Marks */

.selected {
  .selection-mark {
    background-color: $battleship-grey-light;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
  }
  td:first-of-type {
    position: relative;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }
}

.selected.card {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

/* Scrollbars @TODO */
.sidebar-container {
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-container:hover {
  &::-webkit-scrollbar {
    //width: 10px;
    display: flex;
  }
}

.sidebar-container::-webkit-scrollbar {
  position: absolute;
  background-clip: padding-box !important;
  //border-radius: 3px;
  //width: 10px;
  width: 14px;
  height: 18px;

  //-webkit-box-shadow: inset 0 0 30px rgba(200, 200, 200, 1.5);
}

.sidebar-container::-webkit-scrollbar-track {
  background-clip: padding-box !important;
  //border-radius: 3px;
  //width: 8px;
  //-webkit-box-shadow: inset 0 0 30px rgba(200, 200, 200, 0.2);
}

.sidebar-containner::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.sidebar-container::-webkit-scrollbar-thumb {
  //border-radius: 10px;
  //width: 8px;
  //-webkit-box-shadow: inset 0 0 30px rgba(208, 208, 208, 1.0);
  height: 10px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(208, 208, 208, 0.5);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.affix::-webkit-scrollbar {
  display: none;
}

.affix-right::-webkit-scrollbar {
  display: none;
}

.panel-filter::-webkit-scrollbar {
  display: none;
}

.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px;
}

.greeting-image {
  width: 500px;
  margin: 0 auto;
  height: 100%;
  object-fit: contain;
  display: block;
}

.modal-footer div[actions] {
  display: inline-block;
}

.modal-footer div[modal-footer] {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button {
    margin: 0 5px;
    flex-grow: 1;
  }

  > button.btn-primary {
    flex-grow: 2;
  }

  > button:first-child {
    margin-left: 0;
  }

  > button:last-child {
    margin-right: 0;
  }

  > tsq-small-loader {
    flex-grow: 2;
  }
}

#tsq-add-files {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 36px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: none;
  position: relative;

  &.disabled {
    opacity: 0.3;
    background-color: $grey-suit;

    span {
      color: #ffffff;
    }
  }

  #file-select-input {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  img {
    position: absolute;
    left: 24px;
    width: 11px;
    height: 22px;
  }

  span {
    width: 100%;
    opacity: 1;
    color: #7ebc0a;
    text-align: center;
  }
}

.acsb-trigger {
  z-index: 1000 !important;
}

.h-empty-feature,
.h-error-feature {
  height: calc(100vh - 194px);
}

.h-empty-feature-with-tabs,
.h-error-feature-with-tabs {
  height: calc(100vh - 250px);
}

.normalize-last-p p:last-child {
  margin-bottom: 0;
}
