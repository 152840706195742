#menu-more-options {
  width: calc(#{$tsq-sidebar-width-open} - #{$step});
  padding: $half-step;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: $half-step;

  color: white;
  background-color: $primary;
  border-radius: $border-radius-base $border-radius-base 0 0;

  transition: 500ms;

  > span {
    flex-grow: 1;
    font-size: $font-size-small;
    white-space: nowrap;
  }

  > i {
    flex-shrink: 0;
  }
}

#menu-more-options[open = "false"] {
  width: calc(#{$tsq-sidebar-width-close} - #{$half-step});
  left: $quarter-step;
  flex-direction: column;
}

.menu-item {
  padding: $half-step;
  display: flex;
  align-items: center;

  &:hover {
    background-color: darken(white, 3%);
  }

  > img {
    width: 32px;
    flex-shrink: 0;
  }

  > span {
    margin-left: $half-step;
    flex-grow: 1;

    color: $primary;
    font-size: $font-size-base;
    font-family: $font-family-sans-serif-alternative;
    white-space: nowrap;
  }
}

.menu-item.active {
  > span {
    color: $text-primary;
  }
}

.menu-section {
  margin-top: -1px;
  position: relative;
  border-top: 1px solid $line;
  border-bottom: 1px solid $line;

  > span {
    position: absolute;
    top: 0;
    right: $step;
    font-size: $font-size-small;
    transform: rotateZ(-90deg);
    transform-origin: 100% 50% 0;
    color: $text-secondary;
  }
}

.menu-group {
  display: flex;
  flex-direction: column;

  .menu-group-header {
    @extend .menu-item;

    &:hover + .menu-group-hover {
      visibility: visible;
    }

    > span {
      margin-right: $half-step;
      flex-grow: 0;
    }

    > i {
      color: $avocado;
      font-size: $font-size-small;
    }
  }

  .menu-group-content {
    overflow: hidden;
    animation: expandGroup 1000ms;

    > tsq-menu-group-item {
      &:first-child {
        .menu-group-item {
          padding-top: $half-step;
        }
      }

      &:last-child {
        .menu-group-item {
          padding-bottom: $half-step;
        }
      }

      .menu-group-item {
        padding-left: $triple-step;
      }
    }
  }

  .menu-group-hover {
    display: flex;
    align-items: center;

    position: fixed;
    left: $quarter-step;
    z-index: 10;

    visibility: hidden;

    &:hover {
      visibility: visible;
    }

    .menu-group-hover-image {
      padding: $border-radius-base;
      position: relative;

      background-color: white;
      border-radius: $border-radius-base 0 0 $border-radius-base;
      box-shadow: -13px 0px 10px 0px rgba(0, 0, 0, 0.2);

      z-index: 11;

      &:before, &:after {
        content: "";
        position: absolute;

        height: 10px;
        width: 20px;
      }

      &:after {
        top: -15px;
        right: -5px;

        border-radius: 0 0 0 $border-radius-base;
        box-shadow: -10px 0 0 0 white;

        transform: rotateZ(-90deg);
      }

      &:before {
        right: -5px;
        bottom: -15px;

        border-radius: 0 0 $border-radius-base 0;
        box-shadow: 10px 0 0 0 white;

        transform: rotateZ(-90deg);
      }

      > img {
        width: 32px;
      }
    }

    .menu-group-hover-content {
      background-color: white;
      border-radius: $border-radius-base;
      box-shadow: 0 0 20px rgba(28, 46, 82, 0.2);

      > tsq-menu-group-item {
        > .menu-group-item {
          padding: $half-step $step;
        }

        &:first-child {
          > .menu-group-item {
            border-radius: $border-radius-base $border-radius-base 0 0;
          }
        }

        &:last-child {
          > .menu-group-item {
            border-radius: 0 0 $border-radius-base $border-radius-base;
          }
        }

        &:not(:last-child):not(:first-child) {
          > .menu-group-item {
            border-radius: 0;
          }
        }
      }
    }
  }

  .menu-group-item {
    padding: $half-step;
    color: $primary;
    font-size: $font-size-base;
    white-space: nowrap;

    &:hover {
      background-color: darken(white, 3%);
    }
  }

  .menu-group-item.active {
    color: $text-primary;
  }
}

@keyframes expandGroup {
  from {
    max-height: 0;
    box-shadow: inset 0 -8px 10px -5px rgba(0, 0, 0, 0.2);
  }

  to {
    max-height: 300px;
  }
}

.menu-subscription-card {
  height: 129px;
  padding: $half-step $half-step 0;

  display: flex;
  flex-direction: column;

  color: white;
  background-color: $text-primary;
  border-top: 2px solid white;

  overflow: hidden;

  > div {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    > i {
      width: 24px;
      height: 24px;
      padding: 5px 5px 5px $half-step;
      flex-shrink: 0;

      color: $text-primary;
      text-align: center;
      background-color: $primary;
      border-radius: 50%;
    }
  }

  > img {
    margin-top: $half-step;
  }
}
