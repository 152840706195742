:root {
  --green: #7ebc0a;
  --green-n1: #f2f8e7;
  --green-p1: #5e8d07;

  --new-green: #73ce00;
  --new-green-n1: #7de000;
  --new-green-n2: #dcf4be;
  --new-green-n3: #f2fae8;
  --new-green-p1: #559900;
  --new-green-p2: #3f7000;
  --new-green-p3: #00331f;

  --kale: #1b8096;
  --kale-n1: #e8f2f4;
  --kale-p1: #146070;

  --purple: #763ad8;
  --purple-n1: #f1ebfb;
  --purple-p1: #582ba2;

  --red: #d63c3c;
  --red-n1: #fbebeb;
  --red-p1: #a12d2d;

  --yellow: #f1ad00;
  --yellow-n1: #fef7e5;
  --yellow-p1: #d99c00;

  --petro: #fdfdfd;
  --petro-n1: #f7f7f7;
  --petro-n2: #e6e6e6;
  --petro-p1: #fff;

  --coal: #383838;
  --coal-secondary: #636363;
  --coal-tertiary: #929292;

  --box-shadow-hover: 0px 0px 16px rgba(28, 46, 82, 0.08);
  --box-shadow-element: 0px 0px 16px rgba(28, 46, 82, 0.16);
}

.theme-avocado-dark,
.theme-blueberry-dark,
.theme-grape-dark {
  --green: #a5d054;
  --green-n1: #3f5e05;
  --green-p1: #d2e8a9;

  --kale: #5fa6b6;
  --kale-n1: #0d404b;
  --kale-p1: #afd3da;

  --purple: #9f75e4;
  --purple-n1: #3b1d6c;
  --purple-p1: #cfbaf1;

  --red: #e27676;
  --red-n1: #8b2727;
  --red-p1: #f1bbbb;

  --yellow: #f5c64d;
  --yellow-n1: #b58200;
  --yellow-p1: #fae2a6;

  --petro: #313131;
  --petro-n1: #373737;
  --petro-n2: #484848;
  --petro-p1: #2f2f2f;

  --coal: #d5d5d5;
  --coal-secondary: #acacac;
  --coal-tertiary: #828282;

  --box-shadow-hover: 0px 0px 16px rgba(0, 0, 0, 1);
  --box-shadow-element: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.theme-avocado {
  --primary: #7ebc0a;
  --primary-n1: #f2f8e7;
  --primary-p1: #5e8d07;

  --new-primary: #73ce00;
  --new-primary-n1: #7de000;
  --new-primary-n2: #dcf4be;
  --new-primary-n3: #f2fae8;
  --new-primary-p1: #559900;
  --new-primary-p2: #3f7000;
  --new-primary-p3: #00331f;

  --button-primary-new-primary-foreground: var(--new-primary-p3);
}

.theme-avocado-dark {
  --primary: #a5d054;
  --primary-n1: #3f5e05;
  --primary-p1: #d2e8a9;
}

.theme-blueberry {
  --primary: #148fcc;
  --primary-n1: #e8f4fa;
  --primary-p1: #0f6b99;

  --new-primary: #0086ea;
  --new-primary-n1: #2ea4ff;
  --new-primary-n2: #c2e5ff;
  --new-primary-n3: #edf7ff;
  --new-primary-p1: #0174cc;
  --new-primary-p2: #0162ad;
  --new-primary-p3: #004275;

  --button-primary-new-primary-foreground: var(--petro-p1);
}

.theme-blueberry-dark {
  --primary: #5bb0db;
  --primary-n1: #0a4766;
  --primary-p1: #add8ed;
}

.theme-grape {
  --primary: #763ad8;
  --primary-n1: #f1ebfb;
  --primary-p1: #582ba2;
}

.theme-grape-dark {
  --primary: #9f75e4;
  --primary-n1: #3b1d6c;
  --primary-p1: #cfbaf1;
}
