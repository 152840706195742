@forward 'module';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@angular/cdk/overlay-prebuilt.css';
$fa-font-path: '~font-awesome/fonts';
@import 'font-awesome/scss/font-awesome';
@import 'ngx-toastr/toastr';
@import '@tsq-web/styles/variables';
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import 'assets/style/main.scss';
@import '../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../../../node_modules/angular-calendar/css/angular-calendar.css';

html {
  font-size: 16px;
}
