.comment-wrapper {
  min-height: 45px;
  margin: 20px;

  form {
    width: calc(100% - 150px);
    width: -moz-calc(100% - 150px);
    width: -webkit-calc(100% - 150px);
    display: inline-block;
    vertical-align: middle;
  }

  .form-uploader {
    width: calc(75% - 70px);
    width: -moz-calc(75% - 70px);
    width: -webkit-calc(75% - 70px);
  }

  textarea {
    margin-bottom: 0px;
    max-height: 200px;
  }
}

.buttons-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;

  button {
    width: 80px;
  }
}

.buttons-wrapper-uploader {
  width: 100%;
  text-align: right;
  margin: 10px 0 0 0;
}

.uploader-wrapper {
  width: 25%;
  height: 45px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  vertical-align: middle;
  position: relative;

  .comment-add-files {
    width:  100%;
    height: inherit;
    border-radius: 5px;
    border: solid 1.5px $avocado-light;
    opacity: 0.6;

    span {
      width: 100%;
      opacity: 0.6;
      font-size: 12px;
      color: $avocado-light;
    }

    input.file-select-input {
      width: 95%;
      height: 100%;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .uploader-icon {
      display: inline-block;
      margin-left: 5px;
      img {
        width: 20px;
        height: 20px
      }
    }

    .uploader-text {
      width: calc(100% - 35px);
      width: -moz-calc(100% - 35px);
      width: -webkit-calc(100% - 35px);
      max-height: 45px;
      overflow: hidden;
      margin: 2px 0 0 5px;
      display: inline-block;
      vertical-align: middle;
    }

  }
}


/* Different style when used in news feed */
.feed-item-comment {
  .comment-wrapper {
    margin: 20px 0px 0px 0px !important;
    min-height: 30px;

    .simplified-img .img-circle {
      width: 32px;
      height: 32px
    }

    textarea {
      height: 30px;
    }
  }
}
