.alert {
  margin: 15px;
  a {
    color: white;
    text-decoration: underline;
  }
}

.alert-success {
  background-color: $avocado-light;
  font-size: 1.1em;
  color: white;
  text-align: center;
  border: none;
}
.alert-danger {
  background-color: $dusty-orange;
  font-size: 1.1em;
  color: white;
  text-align: center;
  border: none;
}

.alert-info {
  background-color: white;
  border-color: $dusty-orange;
  color: $dusty-orange;
  font-size: 1.1em;
  text-align: center;
  border: 2px solid;
}
