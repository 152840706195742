@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,900);
@import '../templates/layout';
@import '../templates/menu';
@import '../templates/forms';
@import '../templates/pagination';
@import '../templates/general';
@import '../templates/type';
@import '../templates/labels';
@import '../templates/navbar';
@import '../templates/sidebar';
@import '../templates/buttons';
@import '../templates/tables';
@import '../templates/panels';
@import '../templates/alerts';
@import '../templates/loading';
@import '../templates/calendar';
@import '../templates/comment-input';
@import '../templates/brand-simplification-utils';

@import '@tsq-web/styles/buttons';
@import '@tsq-web/styles/chips';
@import '@tsq-web/styles/datepicker';
@import '@tsq-web/styles/dropdown';
@import '@tsq-web/styles/forms';
@import '@tsq-web/styles/layout';
@import '@tsq-web/styles/loading';
@import '@tsq-web/styles/modals';
@import '@tsq-web/styles/navbar';
@import '@tsq-web/styles/switch';
@import '@tsq-web/styles/tags';
@import '@tsq-web/styles/toast';
@import '@tsq-web/styles/type';
@import '@tsq-web/styles/utilities';

.bg-orange {
  background-color: $dusty-orange;
  color: #fff;
}

.bg-dark-blue {
  background-color: $battleship-grey;
  color: #fff;
}

.bg-gray {
  background-color: $sc-gray;
  color: #fff;
}

.bg-solid-blue {
  background-color: $sc-solid-blue;
  color: #fff;
}

.bg-light-blue {
  background-color: $sc-light-blue;
  color: #fff;
}

.loader-container-no-panel {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.md-chat-widget-wrapper {
  visibility: hidden;
  z-index: 1000 !important;
}
