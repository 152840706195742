@tailwind utilities;

$shift: 12px;
$arrow-size: 8px;
$multiline-width: 200px;

@layer utilities {
  [data-tooltip] {
    @apply relative;

    &:before,
    &:after {
      @apply pointer-events-none absolute left-1/2;
      opacity: 0;
      transform: translate(-50%, -$shift);
      transition: opacity 0.35s ease 0.25s;
      user-select: none;
      z-index: 1000;
    }

    &:before {
      content: attr(data-tooltip);
      @apply bg-petro-p1 text-coal-secondary bottom-full whitespace-nowrap rounded p-8 shadow;
    }

    &:after {
      content: '';
      @apply top-0 bg-transparent;
      border: $arrow-size solid transparent;
      border-top-color: var(--petro-p1);
    }

    &:hover,
    &:focus,
    &:active {
      &:before,
      &:after {
        opacity: 1;
      }
    }

    &.tooltip-multiline {
      &:before {
        @apply w-screen whitespace-normal;
        max-width: $multiline-width;
      }
    }

    &[class*='tooltip-bottom'] {
      &:before,
      &:after {
        transform: translate(-50%, $shift);
      }
      &:before {
        @apply bottom-auto top-full;
      }
      &:after {
        border: $arrow-size solid transparent;
        @apply border-b-petro-p1 bottom-0;
      }
    }
    &.tooltip-bottom-left:before {
      transform: translate(-($arrow-size * 3), $shift);
    }
    &.tooltip-bottom-right:before {
      @apply left-auto right-1/2;
      transform: translate($arrow-size * 3, $shift);
    }
    &.tooltip-top-left:before {
      transform: translate(-($arrow-size * 3), -$shift);
    }
    &.tooltip-top-right:before {
      @apply left-auto right-1/2;
      transform: translate($arrow-size * 3, -$shift);
    }

    &[class*='tooltip-left'] {
      &:before,
      &:after {
        @apply top-1/2;
        transform: translate(-$shift, -50%);
      }
      &:before {
        @apply bottom-auto left-auto right-full;
      }
      &:after {
        border: $arrow-size solid transparent;
        @apply border-l-petro-p1 left-0;
      }
    }

    &[class*='tooltip-right'] {
      &:before,
      &:after {
        @apply left-auto top-1/2;
        transform: translate($shift, -50%);
      }
      &:before {
        @apply bottom-auto left-full right-auto;
      }
      &:after {
        border: $arrow-size solid transparent;
        @apply border-r-petro-p1 right-0;
      }
    }
  }
}
