.navbar-default {
  background-color: #FFF;
  border-color: transparent;
}

.navbar-header {
  max-width: calc(100% - 250px);
  display: inline-block;
  float: left;
  white-space: nowrap;

  > button {
    position: absolute;
    right: 0;
  }
}

.navbar-brand {
  float: none !important;
  display: inline-block !important;
  vertical-align: middle;

  img {
    width: 107px;
  }
}


.navbar-partner {
  display: inline-block;

  > img {
    margin-top: 8px;
  }
}

.partner-logo {
  width: auto;
  height: 50px;
  margin-top: 10px;
  margin-left: 11px;
}

@media (max-width: 900px) {
  .navbar-partner {
    display: none;
  }
}
